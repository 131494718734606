<template>
	<div class="">
		<v-app>
			<div class="d-flex-column justify-center align-center">
				<!-- Header Segment -->
				<div>
					<common-home-header :errorDialogue = "error_enable" :authDialogue = "auth_error_enable" :callpermissionflag="callpermissionflag"></common-home-header>
				</div>

				<v-divider></v-divider>

				<div class="navigation-tabs-segment">
					<header-tabs-component :tab_id="0" :is_user_logged_in="isUserLoggedIn"></header-tabs-component>
				</div>

				<v-divider></v-divider>
				<!-- Navigation Bar Segment -->
				<div class="container">
					<!-- Loader Web -->
					<div v-if="skeletonLoaderFlag" class="loader-web">
						<!-- Banner Segment -->
						<div class="banners-segment">
							<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
						</div>

						<!-- MyMed Features Segment -->
						<div class="feature-tabs-container">
							<div class="pa-4">
								<v-skeleton-loader elevation="0" type="heading" class="d-flex justify-center align-center"></v-skeleton-loader>
							
								<div class="pt-4 d-flex justify-space-between">
									<v-skeleton-loader elevation="0" type="image" width="600" class="pr-2"></v-skeleton-loader>

									<v-skeleton-loader elevation="0" type="image" width="600" class="pl-2"></v-skeleton-loader>
								</div>
							</div>
						</div>

						<!-- Explore By Specialties Segment -->
						<div class="specialties-segment">
							<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
						</div>

						<!-- Dynamic Segments -->
						<div class="pb-5 banners-segment">
							<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
						</div>

						<div class="products-segment">
							<v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

							<div class="d-flex">
								<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
								<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
							</div>

							<div class="d-flex">
								<v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
								<v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
							</div>
						</div>

						<div class="pa-4">
							<v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

							<div class="d-flex">
								<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
								<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
							</div>

							<div class="d-flex flex-column">
								<div class="py-2 d-flex">
									<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
									<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
								</div>
								
								<div class="py-2 d-flex">
									<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
									<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
								</div>
							</div>
						</div>

						<div class="pb-5 banners-segment">
							<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
						</div>
					</div>
					<!-- Loader Mobile -->
					<div v-if="skeletonLoaderFlag" class="loader-mobile">
						<!-- Banner Segment -->
						<div class="banners-segment">
							<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
						</div>

						<!-- MyMed Features Segment -->
						<div class="feature-tabs-container">
							<div class="pa-4">
								<v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>
							
								<div class="pt-4 d-flex justify-space-between">
									<v-skeleton-loader elevation="0" type="image" width="600" height="100" class="pr-2"></v-skeleton-loader>

									<v-skeleton-loader elevation="0" type="image" width="600" height="100" class="pl-2"></v-skeleton-loader>
								</div>
							</div>
						</div>

						<!-- Explore By Specialties Segment -->
						<div class="specialties-segment">
							<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
						</div>

						<!-- Dynamic Segments -->
						<div class="pb-5 banners-segment">
							<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
						</div>

						<div class="products-segment">
							<v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

							<div class="d-flex">
								<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
								<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
							</div>

							<div class="d-flex">
								<v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
								<v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
							</div>
						</div>

						<div class="pa-4">
							<v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

							<div class="d-flex">
								<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
								<v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
							</div>

							<div class="d-flex flex-column">
								<div class="py-2 d-flex">
									<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
									<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
								</div>
								
								<div class="py-2 d-flex">
									<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
									<v-skeleton-loader elevation="0" type="card" width="300" height="200" class="px-2"></v-skeleton-loader>
								</div>
							</div>
						</div>

						<div class="pb-5 banners-segment">
							<v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
						</div>
					</div>

					<div v-if="!skeletonLoaderFlag">
						<!-- Banner Segment -->
						<div class="pt-5 banners-segment" v-if="banners.length > 0">
							<banner :key="bannersComponentKey" :banners="banners" banner_type="BANNERS-ONE-PER-SLIDE"></banner>
						</div>

						<!-- MyMed Features Segment -->
						<div class="feature-tabs-container">
							<div class="py-4">
								<p class="body-heading font-weight-semi-bold feature-tabs-title">{{ $t("Customer.Home.What_are_you_looking_for?") }}</p>
							
								<div class="feature-tabs-segment">

									<div class="pa-4 ePharmacy-tab feature-tab clickable-pointer" @click="redirectTo('ePharmacyHome')">
										<div class="display-mobile">
											<div class="d-flex justify-center align-center">
												<img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/ePharamcyIconHome.svg">
											</div>
										</div>

										<div class="display-web">
											<div class="d-flex justify-flex-start">
												<img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/ePharamcyIconHome.svg">
											</div>
										</div>
										
										<p class="ma-0 pt-2 body-heading font-weight-semi-bold feature-text" align="left" style="text-align: start;">{{ $t("Customer.Home.Order_Medicine") }}</p>
									</div>

									<div class="pa-4 teleconsultation-tab feature-tab clickable-pointer" @click="redirectTo('teleconsultationHome')">
										<div class="display-mobile">
											<div class="d-flex justify-center align-center">
												<img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/teleconIconHome.svg">
											</div>
										</div>

										<div class="display-web">
											<div class="d-flex justify-flex-start">
												<img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/teleconIconHome.svg">
											</div>
										</div>
										<p class="ma-0 pt-2 body-heading font-weight-semi-bold feature-text" align="left" style="text-align: start;">{{ $t("Customer.Home.Book_Appointment") }}</p>
									</div>

									<div class="pa-4 healtharticles-tab feature-tab clickable-pointer" @click="redirectTo('HealthArticles')">
										<div class="display-mobile">
											<div class="d-flex justify-center align-center">
												<img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/healtharticle.svg">
											</div>
										</div>

										<div class="display-web">
											<div class="d-flex justify-flex-start">
												<img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/healtharticle.svg">
											</div>
										</div>
										<p class="ma-0 pt-2 body-heading font-weight-semi-bold feature-text" align="left" style="text-align: start;">Health Articles</p>
									</div>

									<!-- <div class="pa-4 nurseBooking-tab feature-tab clickable-pointer" @click="redirectTo('booknurse')">
										<div class="display-mobile">
											<div class="d-flex justify-center align-center">
												<img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/bookNurse.svg">
											</div>
										</div>

										<div class="display-web">
											<div class="d-flex justify-flex-start">
												<img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/bookNurse.svg">
											</div>
										</div>
										
										<p class="ma-0 pt-2 body-heading font-weight-semi-bold feature-text" align="center">Book Nurse </p>
									</div> -->
								</div>
								<!-- <div class="feature-tabs-segment" style="margin-top: 16px;">
									<div v-if="opdEnable" class="pt-4 pl-4 pb-4 opdBooking-tab feature-tab clickable-pointer" @click="redirectTo('opdBooking')" 
									:class="(opdEnable && packageEnable) ? 'width-50' : 'width-100'" >
										<div class="display-mobile">
											<div class="d-flex justify-center align-center">
												<img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/OPDiconHome.svg">
											</div>
										</div>
										
										<div class="display-web">
											<div class="d-flex justify-flex-start">
												<img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/OPDiconHome.svg">
											</div>
										</div>
										<p class="ma-0 pt-2 body-heading font-weight-semi-bold feature-text" align="left" style="text-align: start; padding-right: 4px;">{{ $t("Customer.Home.OPD_Appointments") }}</p>
									</div>
									<div v-if="packageEnable" class="pt-4 pl-4 pb-4 packageBooking-tab feature-tab clickable-pointer" @click="redirectTo('packageBooking')"
									:class="(opdEnable && packageEnable) ? 'width-50' : 'width-100'" >
										<div class="display-mobile">
											<div class="d-flex justify-center align-center">
												<img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/packageIconHome.svg">
											</div>
										</div>
										
										<div class="display-web">
											<div class="d-flex justify-flex-start">
												<img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/packageIconHome.svg">
											</div>
										</div>
										<p class="ma-0 pt-2 body-heading font-weight-semi-bold feature-text" align="left" style="text-align: start; padding-right: 4px;">{{ $t("Customer.Home.Lab_Packages") }}</p>
									</div>
								</div> -->
							</div>
						</div>
						<!-- UPCOMING HOSPITAL APPOINTMENTS -->
                        <today-appointment-home-hospital />
                        <!-- UPCOMING HOSPITAL APPOINTMENTS END -->
						<!-- Explore By Specialties Segment -->
						<div class="specialties-segment" v-if="specializations.length > 0">
							<div>
								<explore-specialities-component @handleCategorySizeChange="handleCategorySizeChange" :key="specializationComponentKey" :categories="specializations" category_type="Teleconsultation" :header_data="exploreSpecHeader"></explore-specialities-component>
							</div>
						</div>
						
						<!-- Dynamic Segments -->
						<div class="pb-sm-4" v-if="segmentComponents.length > 0">
							<div class="" v-for="segment, index in segmentComponents" :key="index">
								<div v-if="segment.type === 'BANNERS-ONE-PER-SLIDE' || segment.type === 'BANNERS-THREE-PER-SLIDE'" class="banners-segment">
									<div>
										<banner :key="bannersComponentKey" :banners="segment.items" :banner_type="segment.type"></banner>
									</div>
								</div>

								<div class="products-segment" v-if="segment.type === 'LISTINGS-ONE-ROW'">
									<div>
										<listings-component
											:data="segment"
											:buttonText="segment.item_type == 'PRODUCTS' || segment.item_type == 'COLLECTIONS' ? 'Add To Cart' : 'View Slot'"
											listings_type="LISTINGS-ONE-ROW"
										></listings-component>
									</div>
								</div>

								<div class="products-segment" v-if="segment.type === 'LISTINGS-TWO-ROWS'">
									<div>
										<listings-component
											:data="segment"
											:buttonText="segment.item_type == 'PRODUCTS' || segment.item_type == 'COLLECTIONS' ? 'Add To Cart' : 'View Slot'"
											listings_type="LISTINGS-TWO-ROWS"
										></listings-component>
									</div>
								</div>

								<div class="products-segment" v-if="segment.type === 'ARTICLES'">
									<listings-component
										:data="segment"
										listings_type="ARTICLES"
									></listings-component>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="display-web">
					<v-divider></v-divider>
				</div>
			</div>

			<!-- Bottom Navigation Bar (Mobile) -->
			<div class="bottom-navbar-segment mobile-bottom-nav-bar">
				<bottom-nav-bar-component :selectedOption="0" />
				<!-- <bottom-navigation-bar highlight_icon="home"></bottom-navigation-bar> -->
			</div>

			<!-- Footer (Web) -->
			<div class="pa-4 footer-segment">
				<common-home-footer></common-home-footer>
			</div>
		</v-app>
	</div>
</template>

<script>
import { axios_auth_instance_epharmacy_customer, axios_auth_instance_nba_admin } from '../utils/axios_utils';
import axios from "axios";
export default {
	name: 'CommonHomePage',
	components: {
		'common-home-header': () => import('../components/navigationComponents/commonHomeHeader.vue'),
		'header-tabs-component': () => import('../components/navigationComponents/headerTabsComponent.vue'),
		'banner': () => import('../components/ePharmacyComponents/bannersHomeComponent.vue'),
		'explore-specialities-component': () => import('../components/ePharmacyComponents/categoryHomePageComponent.vue'),
		'listings-component': () => import('../components/ePharmacyComponents/multipleStyleListingsComponent.vue'),
		'common-home-footer': () => import('../components/navigationComponents/commonHomeFooter.vue'),
		'bottom-nav-bar-component': () => import('../components/nurseBookingPageComponents/bottomNavBarComponent.vue'),
		'today-appointment-home-hospital': () => import('../views/OPDBookingPages/mainPages/todayAppointmentHomeHospital.vue')
	},
	data() {
		return {
			screenWidth: window.innerWidth,
			skeletonLoaderFlag: true,
			isUserLoggedIn: false,
			cart_quantity: null,
			banners: [],
			segmentComponents: [],
			bannersComponentKey: 0,
			specializations: [],
			specializationComponentKey: 0,
			categories: [],
			categoriesComponentKey: 0,
			productsList: [],
			productsList3: [],
			exploreSpecHeader: {},
			exploreByHealthConcern: {},
			sliderSegmentData1: null,
			sliderSegmentData2: null,
			sliderSegmentData3: null,
			sliderSegmentData4: null,
			productSliderData1: null,
			productSliderData2: null,
			bgColorOfComponent: '',
			buttonTextForComponent: '',
			callpermissionflag : false,
			error_enable: false,
			auth_error_enable: false,
			token: null,
			opdEnable: true,
			packageEnable: true,
			HBAsettingResponse: null,
		}
	},
	watch: {
		screenWidth(newWidth, oldWidth) {
			// console.log('Screen Width Changed:', newWidth);
		},
	},
	created() {
		if (!window.xm) {
			this.$router.push({
				name: 'CustomerHomeScreen'
			});
		} 
		// Commenting the else part to avoid infinite refreshes in case this is appcube
		// else {
		// 	if (this.$router.history.current.name == 'CommonHomePage') {
		// 		this.$router.go();
		// 	} else {
		// 		this.$router.push({
		// 			path: '/'
		// 		});
		// 	}
		// }
	},
	async mounted() {
		if (window.xm){
      		document.title = ' ';
			this.token = localStorage.getItem('customerToken');
    	} else {
			document.title = 'Home';
			this.token = this.$cookies.get('customerToken');
		}
		if (this.$store.state.locale !== "") {
			this.$i18n.locale =  this.$store.state.locale;
		}
		else {
			this.$i18n.locale = "mm";
			this.$store.dispatch("changeLocale", this.$i18n.locale);
		}
		window.addEventListener('resize', this.handleResize);
		try{ 
			this.HBAsettingResponse = await axios_auth_instance_nba_admin.get('/hba/pagewise/v1/HBAsettingStatus');
			console.log("hbasetting",this.HBAsettingResponse)
			this.opdEnable = this.HBAsettingResponse.data.data[0].availability_status === "AVAILABLE" ? true : false;
        	this.packageEnable = this.HBAsettingResponse.data.data[1].availability_status === "AVAILABLE" ? true : false;
		} catch{
			console.log('error while fetching data', this.HBAsettingResponse);
		}
		

		// Fetch data from pagewise API.
		console.log("going to call commonHomePage pagewise API");
		let config = {};
		let token = 'null';
		if (!window.xm)
			token = this.$cookies.get('customerToken');
		else
			token = localStorage.getItem('customerToken');
		let kbzAppToken = '';
		let xmresponse = null;
		// if (window.xm) {
		// 	console.log("Accessing through KBZPay, getting kbzpay token in common home page");
		// 	xmresponse = await window.xm.native('getKBZPayToken', { appid: process.env.VUE_APP_MERCH_ID });
		// 	kbzAppToken = xmresponse.token;
		// }
		// config.headers = {};
		// config.headers['authorization'] = `bearer ${token}##myMed##${kbzAppToken}`;
		// console.log(`Authorization: bearer ${token}##myMed##${kbzAppToken}`);
		axios_auth_instance_epharmacy_customer.get('/pagewise/commonHomePage').then((commonHomePageResponse) => {
			// Check authentication status.
			this.isUserLoggedIn = commonHomePageResponse.data.authenticationStatus == 401 ? false : true;
			if (commonHomePageResponse.data.customerBasicData) {
				if (commonHomePageResponse.data.customerBasicData.language == 'en') {
					this.$i18n.locale = 'en';
				} else {
					this.$i18n.locale = 'mm';
				}
	            this.$store.dispatch("changeLocale", this.$i18n.locale);
            	this.$store.dispatch("changeName", commonHomePageResponse.data.customerBasicData.name);
            	this.token = commonHomePageResponse.data.customerBasicData.newToken;
            	if (this.token) {
	            	if(!window.xm)
	            	    this.$cookies.set('customerToken', this.token, '1w');
	            	else
	            	    localStorage.setItem('customerToken', this.token);
	            }
			}
			this.display_data = commonHomePageResponse.data.data.display_data;

			// Need Cart Quantity.
			this.cart_quantity = this.display_data.cart_quantity;

			// Banners Segment.
			this.banners = this.display_data.banners;

			// Specializations Segment.
			this.exploreSpecHeader = {
				icon: 'https://s3iconimages.mymedicine.com.mm/explore_by_specialities_home_icon.svg',
				heading_name: 'Explore By Specialities',
				pagename: 'SpecializationsPage'
			};
			if (this.display_data.specializations) {
				this.specializations = this.display_data.specializations;
			}

			// Slider Segments.(Product & Doctors)
			this.segmentComponents = this.display_data.segments;

			this.skeletonLoaderFlag = false;
			this.callpermissionflag = true;
		}).catch((commonHomePageError) => {
			if (commonHomePageError.response && commonHomePageError.response.status && commonHomePageError.reponse.status === 401) {
				if(!window.xm)
					this.$cookies.remove('customerToken');
				else
					localStorage.removeItem('customerToken');
				this.$router.go();
			} else {
				console.log('Error fetching common home page data: ', commonHomePageError);
				this.error_enable = true;
			}
		});
		this.userPageViewTable();
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName : 'commonHomePage',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'commonHomePage',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
		handleResize() {
			this.skeletonLoaderFlag = true;
			this.screenWidth = window.innerWidth;
			this.bannersComponentKey++;
			this.specializationComponentKey++;
			this.categoriesComponentKey++;
			
			this.skeletonLoaderFlag = false;
		},
		handleCategorySizeChange() {
			this.skeletonLoaderFlag = true;
			this.specializationComponentKey++;

			this.skeletonLoaderFlag = false;
		},
		redirectTo(redirectionPage) {
			if (redirectionPage == 'ePharmacyHome') {
				// window.open(window.location.origin + '/customer/ePharmacyHome');
				if (!window.xm) {
                    this.$router.push({
                        name: 'CustomerHomeScreen'
                    });
                } else {
                    this.$router.push({
                        name: "ePharmacyHomePage"
                    });
                }
			} else if (redirectionPage == 'teleconsultationHome') {
				if (this.screenWidth <= 600) {
					this.$router.push({
						name: 'CustomerHomeScreen'
					});
				} else {
					window.open(window.location.origin + '/customer/teleconsultationHome');
				}
			} else if (redirectionPage == 'HealthArticles') {
				this.$router.push({
					name: 'HealthArticles'
				});
			} else if (redirectionPage == 'booknurse') {
				this.$router.push({
					name: 'NurseBookingCommonPage'
				});
			} else if(redirectionPage == 'opdBooking') {
				this.$router.push({
					name:'OPDHome'
				})
			} else if(redirectionPage == 'packageBooking') {
				this.$router.push({
					name:'OPDPackageHome'
				})
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '../scss/global.scss';
@import '../scss/classes.scss';
.loader-web, .footer-segment, .navigation-tabs-segment, .display-web {
	@media (max-width: 600px) {
        display: none !important;
	}
}

.loader-mobile, .mobile-bottom-nav-bar, .display-mobile {
	@media (max-width: 600px) {
        
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		display: none !important;
	}

	@media (min-width: 1265px) {
		display: none !important;
	}
}
.banners-segment {
	width: 100%;
	height: auto;

	@media (max-width: 600px) {
        padding-top: calc( $content-padding-mobile / 2);
		padding-bottom: $content-padding-mobile;
		padding-left: $margin-header-x-mobile;
		padding-right: $margin-header-x-mobile;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		padding-top: calc( $content-padding-medium / 2);
		padding-bottom: calc( $content-padding-medium / 2);
		padding-left: $margin-header-x-medium;
		padding-right: $margin-header-x-medium;
	}

	@media (min-width: 1265px) {
		padding-top: calc( $content-padding-web / 2);
		padding-bottom: calc( $content-padding-web / 2);
		padding-left: $margin-header-x-web;
		padding-right: $margin-header-x-web;
	}
}
.specialties-segment {
	@media (max-width: 600px) {
        padding-top: calc( $content-padding-mobile / 2);
		padding-bottom: calc( $content-padding-mobile / 2);
		margin-left: $margin-header-x-mobile;
		margin-right: $margin-header-x-mobile;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		padding-top: $content-padding-medium;
		padding-bottom: $content-padding-medium;
		margin-left: $margin-header-x-medium;
		margin-right: $margin-header-x-medium;

		border-radius: 8px;
	}

	@media (min-width: 1265px) {
		padding-top: $content-padding-web;
		padding-bottom: $content-padding-web;
		margin-left: $margin-header-x-web;
		margin-right: $margin-header-x-web;

		border-radius: 8px;
	}
}
.products-segment {
	@media (min-width: 601px) and (max-width: 1264px) {
		padding-top: calc($content-padding-medium / 2);
		padding-bottom: calc($content-padding-medium / 2);
		margin-left: $margin-header-x-medium;
		margin-right: $margin-header-x-medium;

		// border-radius: 8px;
	}

	@media (min-width: 1265px) {
		padding-top: calc( $content-padding-web / 2);
		padding-bottom: calc( $content-padding-web / 2);
		margin-left: $margin-header-x-web;
		margin-right: $margin-header-x-web;

		// border-radius: 8px;
	}
}
.feature-tabs-container {
	background-color: #F4F6FA;

	@media (max-width: 600px) {
        margin-top: calc($content-padding-mobile / 2);
		margin-bottom: calc($content-padding-mobile / 2);
		padding-left: $margin-header-x-mobile;
		padding-right: $margin-header-x-mobile;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		margin-top: calc($content-padding-medium / 2);
		margin-bottom: calc($content-padding-medium / 2);
		margin-left: $margin-header-x-medium;
		margin-right: $margin-header-x-medium;

		border-radius: 8px;
	}

	@media (min-width: 1265px) {
		margin-top: calc($content-padding-web / 2);
		margin-bottom: calc($content-padding-web / 2);
		margin-left: $margin-header-x-web;
		margin-right: $margin-header-x-web;

		border-radius: 8px;
	}
}

.promo-banner-segment {
	@media (max-width: 600px) {
        padding-top: calc( $content-padding-mobile / 2);
		padding-bottom: calc( $content-padding-mobile / 2);
		padding-left: $margin-header-x-mobile;
		padding-right: $margin-header-x-mobile;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		padding-top: calc($content-padding-medium / 2);
		padding-bottom: calc($content-padding-medium / 2);
		margin-left: $margin-header-x-medium;
		margin-right: $margin-header-x-medium;
	}

	@media (min-width: 1265px) {
		padding-top: calc($content-padding-web / 2);
		padding-bottom: calc($content-padding-web / 2);
		margin-left: $margin-header-x-web;
		margin-right: $margin-header-x-web;
	}
}
.feature-tabs-title {
	@media (max-width: 600px) {
        display: flex;
		justify-content: flex-start;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	@media (min-width: 1265px) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.feature-tabs-segment {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 16px;
	margin-left: auto;
	margin-right: auto;

	@media (max-width: 600px) {
        width: 100%;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		width: 60%;
	}

	@media (min-width: 1265px) {
		width: 60%;
	}
}

.feature-tab {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 8px;
	padding: 8px;
	// max-width: 30%;
	width: 50% !important;
	align-items: start;
}

.feature-text {
	color: white;

	@media (max-width: 600px) {
		text-align: center;
	}

	@media (min-width: 601px) and (max-width: 1520px) {
		text-align: left;
	}

	@media (min-width: 1521px) {
		text-align: left;
	}
}
.teleconsultation-tab {
	background-color: #F89035;
}
.ePharmacy-tab {
	background-color: #1467BF;
}
.healtharticles-tab {
	background: linear-gradient(121.69deg, #DE1621 0%, #EB5757 100%);
}
.nurseBooking-tab{
	background: #DE1621;
}
.opdBooking-tab{
	background: linear-gradient(135deg, #57CDFF -0.78%, #006BDC 99.22%);
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 4px;

}
.packageBooking-tab{
	background: linear-gradient(121.69deg, #FFA51F 0%, #EB5757 100%);

}
.width-50 {
    width: 50% !important;
}

.width-100 {
    width: 100% !important;
}
.icon-class {
	padding-bottom: 8px;
	object-fit: fill;

	@media (max-width: 600px) {
		width: 32px;
		height: 32px;
		justify-content: center;
	}

	@media (min-width: 601px) {
		width: 45px;
		height: 45px;
		justify-content: left;
	}
}
.banner-image {
	width: 100%;
    height: 100%;
    object-fit: fill;
}

.search-container, .search-segment {
	width: 100%;
}
.search-bar {
	// width: 90vw;
	border-radius: 8px;
}
</style>

<style lang="scss">
@import '../scss/global.scss';
@import '../scss/classes.scss';

</style>